import React, { useState, useEffect } from 'react';
import './DTCProductBuilder.less';
import { Switch, Route, Redirect } from 'react-router-dom';
import DTCProductTable from 'components/DTCProductTable/DTCProductTable';
import { Select } from 'antd';
import * as api from 'util/api.js';
import STORES from 'config/stores';
import dayjs from 'dayjs';
import { getCountryFromIP } from 'util/geolocation';
import DTCProductBuilderFormContent from './DTCProductBuilderFormContent/DTCProductBuilderFormContent';
import { baseVariant, packVariant } from './utils/Variants';

const DTCProductBuilder = () => {
  const [prices, setPrices] = useState([]);
  const [store, setStore] = useState(STORES.GPNZ);
  const storeIsNZ = [STORES.GPNZ, STORES.DWNZ].includes(store);

  const [defaultStore, setDefaultStore] = useState();
  useEffect(async () => {
    const country = await getCountryFromIP();
    setDefaultStore(country.code === 'AU' ? STORES.GPAU : STORES.GPNZ);
  }, []);

  const { Option } = Select;

  const fetchPrices = () => {
    console.log('loading...');
    api.getDtcPricing().then((data) => {
      setPrices(data);
      console.log('data is:', data);
      console.log('...setting data');
    }).catch((error) => {
      console.error('Failed to fetch pricing data', error);
    });
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  const [generatorStatus, setGeneratorStatus] = useState({
    active: false,
    error: null,
    success: false,
  });

  const StoreButton = () => (
    <Select
      className="generator-store-button"
      defaultValue={defaultStore}
      style={{ width: 180 }}
      value={store}
      onChange={(newStore) => {
        setStore(STORES[newStore]);
      }}
    >
      <Option value={STORES.GPNZ}>Garage Project NZ</Option>
      <Option value={STORES.GPAU}>Garage Project AU</Option>
    </Select>
  );

  const generateProductObject = ({
    type, productId, product, formData, dtcPrices,
  }) => {
    const packConfigs = {
      750: {
        type: 'Bottle',
        weight: '1200',
        barcodeCol: 'barcodeBaseBottle.750',
      },
      375: {
        type: 'Bottle',
        weight: '500',
        barcodeCol: 'barcodeBaseBottle.375',
      },
      330: {
        type: 'Can',
        weight: '375',
        barcodeCol: 'barcodeBaseCan.330',
      },
      440: {
        type: 'Can',
        weight: '460',
        barcodeCol: 'barcodeBaseCan.440',
      },
    };
    const { productFilters } = formData;
    const arrayToCommaSeparatedString = (inputArray) => {
      // see 'filters' in metafields Array below
      let productFilterArray;
      if (typeof inputArray === 'string') {
        try {
          productFilterArray = JSON.parse(inputArray);
        } catch (error) {
          console.error('Error parsing input string:', error);
          return '';
        }
      } else if (Array.isArray(inputArray)) {
        productFilterArray = inputArray;
      } else {
        console.error('Input is neither a string nor an array');
        return '';
      }
      return productFilterArray.filter((filter) => typeof filter === 'string').join(',');
    };
    console.log('Original productFilters:', productFilters);
    console.log('productFilters type:', typeof productFilters);
    const formattedProductFilters = arrayToCommaSeparatedString(productFilters);

    const customSlab = parseFloat(formData.selectedSlab || '15');
    const floatedRRP = parseFloat(formData.selectedRRP || product.rrp);
    const baseWeight = parseFloat(packConfigs[formData.unitSize].weight);
    const packType = packConfigs[formData.unitSize].type;
    const unitBarcode = product[packConfigs[formData.unitSize].barcodeCol];
    const matchingDtcPrice = dtcPrices?.find((entry) => parseFloat(entry?.baseUnitPrice) === floatedRRP && parseFloat(entry.baseUnit) === parseFloat(formData.unitSize));
    const formattedDate = product.dateReleased ? dayjs(product.dateReleased).format('MMMM YYYY') : '';

    // const ingredients = () => {
    //   const { malts, hops } = product;
    //   return malts || hops ? `${hops ? `${hops} hops` : ''}${malts && hops ? ', ' : ''}${malts ? `${malts} malt` : ''}` : '';
    // };
    const ingredients = () => {
      const { malts, hops } = product;

      const parseIngredients = (rawIngredients) => rawIngredients
        .replace(/\n/g, ', ')
        .replace(/,\s*,/g, ', ')
        .replace(/,\s*$/, '');

      const parsedHops = hops ? parseIngredients(hops) : '';
      const parsedMalts = malts ? parseIngredients(malts) : '';

      return parsedHops || parsedMalts
        ? `${parsedHops ? `${parsedHops} hops` : ''}${parsedHops && parsedMalts ? ', ' : ''}${parsedMalts ? `${parsedMalts} malt` : ''}`
        : '';
    };

    console.log('Ingredients', ingredients());
    const metafields = [
      {
        key: 'variety',
        value: product.oneLiner.replace(/\n/g, ''),
        type: 'single_line_text_field',
        namespace: 'global',
      },
      {
        key: 'style',
        value: product.oneLiner.replace(/\n/g, ''),
        type: 'single_line_text_field',
        namespace: 'global',
      },
      {
        key: 'percentage',
        value: `${product.abv}%`,
        type: 'single_line_text_field',
        namespace: 'global',
      },
      {
        key: 'brewed',
        value: ingredients(),
        type: 'single_line_text_field',
        namespace: 'global',
      },
      {
        key: 'artist',
        value: product.artist,
        type: 'single_line_text_field',
        namespace: 'global',
      },
      {
        key: 'release',
        value: formattedDate,
        type: 'single_line_text_field',
        namespace: 'global',
      },
      {
        key: 'bundled',
        value: true,
        type: 'single_line_text_field', // configured as boolean in Shopify. CLI does not accept 'boolean' as a type >:^(
        namespace: 'global',
      },
      {
        key: 'coming_soon',
        value: true,
        type: 'single_line_text_field', // configured as boolean in Shopify. CLI does not accept 'boolean' as a type >:^(
        namespace: 'global',
      },
      {
        key: 'filters',
        value: formattedProductFilters,
        type: 'single_line_text_field',
        // Should be list.single_line_text_field with an array of productFilters but
        // Metafield Manager uses the deprecated 'String (Old)' type and parses the list on the ',' so this has to be a comma separated string (https://metafieldsmanager.thebestagency.com/)
        namespace: 'global',
      },
    ];
    const variants = [
      baseVariant(formData, baseWeight, floatedRRP, packConfigs, unitBarcode),
    ];
    if (packType === 'Can') {
      const fourPackPrice = store === 'GPNZ' ? matchingDtcPrice?.fourPackPrice : (floatedRRP * 4).toFixed(2);
      variants.push(packVariant('4-Pack', 4, formData, baseWeight, fourPackPrice, packConfigs));
    }
    if (packType === 'Can' && formData.unitSize === '330' && store === 'GPAU') {
      variants.push(packVariant('24-Pack', 24, formData, baseWeight, floatedRRP * 24 * 0.9, packConfigs));
    } else if (packType === 'Bottle' && formData.unitSize === '375') {
      const fourPackPrice = floatedRRP * 4;
      const twelvePackPrice = (floatedRRP * 12) - 12;
      variants.push(packVariant('4-Pack', 4, formData, baseWeight, fourPackPrice, packConfigs));
      variants.push(packVariant('12-Pack', 12, formData, baseWeight, twelvePackPrice, packConfigs));
    } else if (packType === 'Bottle' && formData.unitSize === '750') {
      const sixPackPrice = floatedRRP * 6;
      variants.push(packVariant('6-Pack', 6, formData, baseWeight, sixPackPrice, packConfigs));
    }
    if (store === 'GPNZ' && packType === 'Can') {
      const sixPackPrice = matchingDtcPrice?.sixPackPrice || (floatedRRP * 6).toFixed(2);
      const slabMultiplier = customSlab === 24 ? 0.9 : 0.925;
      const slabPrice = (floatedRRP * customSlab * slabMultiplier).toFixed(2);
      variants.push(packVariant('6-Pack', 6, formData, baseWeight, sixPackPrice, packConfigs));
      variants.push(packVariant(`${customSlab}-Pack`, customSlab, formData, baseWeight, slabPrice, packConfigs));
    } else if (store === 'GPAU' && formData.unitSize === '440') {
      const slabPrice = (floatedRRP * customSlab * 0.925).toFixed(2);
      variants.push(packVariant(`${customSlab}-Pack`, customSlab, formData, baseWeight, slabPrice, packConfigs));
    }

    const productPayload = {
      product: {
        title: product.name,
        body_html: product.blurb,
        vendor: 'Garage Project',
        status: 'draft',
        product_type: 'Cans',
        metafields,
        variants,
        options: [{
          name: 'Size',
        }],
      },
    };
    console.log('Product payload for Shopify:', productPayload);
    return productPayload;
  };

  const handleProductGeneration = async ({ type, data, dtcPrices }) => {
    const { formData, product } = data;
    try {
      setGeneratorStatus({
        active: true,
        error: null,
        success: null,
      });
      const shopifyProduct = await generateProductObject({
        type,
        productId: product.id,
        product,
        formData: {
          ...formData,
        },
        dtcPrices,
      });
      api.createNewShopifyProduct(store, shopifyProduct);
      setGeneratorStatus({
        active: false,
        error: null,
        success: true,
      });
    } catch (error) {
      setGeneratorStatus({
        active: false,
        error: error.response?.data.error.userError ?? 'There was an error generating the product',
        success: false,
      });
    } finally {
      setGeneratorStatus((prev) => ({ ...prev, active: false, success: false }));
    }
  };

  return (
    <Switch>
      {[
        {
          type: 'shopify',
        },
        {
          type: 'kounta',
        },
      ].map(({ type }) => (
        <Route
          key={type}
          exact
          path={`/dashboard/dtc-product-builder/${type}`}
          render={() => (
            <DTCProductTable
              store={store}
              StoreButton={StoreButton}
              prices={prices}
              type={type}
              generatorStatus={generatorStatus}
              selectionType="single"
              pluralLabel={`${type} labels`}
              GeneratorFormContent={DTCProductBuilderFormContent}
              onGenerate={(data) => handleProductGeneration({ type, data, dtcPrices: prices })}
            />
          )}
        />
      ))}
      <Redirect to="/dashboard/dtc-product-builder/shopify" />
    </Switch>
  );
};

export default DTCProductBuilder;
