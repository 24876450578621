import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './DTCProductBuilderFormContent.less';
import {
  Form, Input, Radio, Select, Alert, Avatar, Typography, Tooltip, InputNumber, Checkbox,
} from 'antd';
import { DatePicker, HoverHelp } from 'components';
import { capitalCase } from 'change-case';
import { isNull } from 'lodash';

const { Text } = Typography;

const { Option } = Select;

const DTCProductBuilderFormContent = ({
  type, prices, products, form, modalOpen, store,
}) => {
  const [product] = products;
  const getUnitSizes = (productData) => {
    console.log(productData);
    const barcodeBaseRegex = /^barcodeBase\w*\.\d+$/; // Matches keys like 'barcodeBaseCan.440'
    const uniqueValues = new Set();
    Object.entries(productData || {})
      .filter(([key, value]) => key.startsWith('barcodeBase') && value !== null)
      .forEach(([key]) => {
        const baseValue = key.split('.')[1];
        if (barcodeBaseRegex.test(key)) {
          uniqueValues.add(baseValue);
        }
      });

    return Array.from(uniqueValues);
  };

  const unitSizes = getUnitSizes(product);

  if (!product) {
    return null;
  }
  console.log('store: ', product);
  const getRRPOptions = (rrp) => {
    if (!rrp) {
      return [];
    }
    if (/^\d+(\.\d+)?$/.test(rrp)) return [rrp];
    return rrp.split(/[ ,/]+/).map((e) => e.replace(/[^\d.]/g, '')).filter(Boolean);
  };
  console.log('store: ', store);
  const rrpOptions = getRRPOptions(store === 'GPNZ' ? product.rrp : product.rrpAud);
  console.log('store: ', rrpOptions);

  const filterOptions = ['Limited', 'Core', 'Return', 'Vintage', 'Collab', 'FRESH',
    'Pack', 'Wild Workshop', 'IPA', 'West Coast', 'Hazy', 'Lager', 'Dark', 'Ale', 'Pale Ale', 'Sour', 'Low & No',
  ];

  const ProductFilters = () => (
    <Form.Item
      name="productFilters"
      label="Preconfigure Available Shopify Filters"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      type="array"
    >
      <Select
        mode="multiple"
        placeholder="Select filters"
        defaultValue={[]}
        style={{ width: '100%' }}
        options={filterOptions.map((option) => ({ value: option, label: option }))}
      />
    </Form.Item>
  );

  const CustomSlabs = () => (
    <Form.Item
      name="selectedSlab"
      label="Select Slab size"
      rules={[{ required: true, message: 'Please select a slab size.' }]}
      initialValue={store === 'GPNZ' ? 24 : 15}
    >
      <Radio.Group>
        <Radio key={15} value={15}>15-Pack</Radio>
        <Radio key={16} value={16}>16-Pack</Radio>
        <Radio key={24} value={24}>24-Pack</Radio>
      </Radio.Group>
    </Form.Item>
  );

  return (
    <>
      <div className="modal-header">
        <h1>{product.name}</h1>
        <img src={product?.tapBadgeUrl} alt="product tap badge" />
      </div>
      <Form.Item
        name="unitSize"
        label="Unit Size (mL)"
        rules={[{ required: true, message: 'Unit type is required' }]}
      >
        <Select>
          {unitSizes.map((value) => (
            <Select.Option key={value} value={value}>
              {value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="selectedRRP"
        label="Select RRP"
        rules={[{ required: true, message: 'Please select an RRP value' }]}
      >
        <Select>
          {rrpOptions.map((value) => (
            <Option key={value} value={value}>{value}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="SKU"
        label="SKU"
        rules={[{ required: true, message: 'SKU is required' }]}
      >
        <Input placeholder="Input a 5 letter SKU" maxLength={5} />
      </Form.Item>
      {unitSizes.includes('440', '330') && <CustomSlabs />}
      <ProductFilters />
    </>
  );
};

DTCProductBuilderFormContent.propTypes = {
  type: PropTypes.oneOf(['shopify', 'kounta']).isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  // Disabled since ant form shape may change
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  modalOpen: PropTypes.bool.isRequired,
};

export default DTCProductBuilderFormContent;
