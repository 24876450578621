import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Divider } from 'antd';

// TODO: Generate automatically based on unitDetails.js

const Barcodes = ({ type }) => {
  switch (type) {
    case 'base':
      return <BaseBarcodes />;
    case 'slab':
      return <SlabBarcodes />;
    case 'case':
      return <CaseBarcodes />;
    // case 'keg':
    //   return <KegBarcodes />;
    default:
      return null;
  }
};

const BaseBarcodes = () => (
  <div className="column">
    <h2>Individual Cans</h2>
    <Form.Item
      name="barcodeBaseCan.250"
      label="250ml Can"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeBaseCan.330"
      label="330ml Can"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeBaseCan.375"
      label="375ml Can"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeBaseCan.440"
      label="440ml Can"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeBaseBottle.330"
      label="330ml Bottle"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeBaseBottle.375"
      label="375ml Bottle"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeBaseBottle.650"
      label="650ml Can"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeBaseBottle.750"
      label="750ml Bottle"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <h2>Packs</h2>
    <Form.Item
      name="barcodeBaseCan.330.4"
      label="330ml Can (4 Pack)"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeBaseCan.330.6"
      label="330ml Can (6 Pack)"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeBaseCan.330.10"
      label="330ml Can (10 Pack)"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeBaseBottle.330.6"
      label="330ml Bottle (6 Pack)"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeBaseCan.440.4"
      label="440ml Can (4 Pack)"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeBaseCan.250.4"
      label="250ml Can (4 Pack)"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
  </div>
);

const SlabBarcodes = () => (
  <div className="column">
    <h2>Individual Cans</h2>
    <Form.Item
      name="barcodeSlab.250"
      label="Slab of 250ml Cans"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeSlab.16x.330"
      label="Slab of 16 x 330ml Cans"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeSlab.24x.330"
      label="Slab of 24 x 330ml Cans"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeSlab.375"
      label="Slab of 375ml Cans"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeSlab.15x.440"
      label="Slab of 15 x 440ml Cans"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeSlab.16x.440"
      label="Slab of 16 x 440ml Cans"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeSlab.24x.440"
      label="Slab of 24 x 440ml Cans"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <h2>Packs</h2>
    <Form.Item
      name="barcodeSlab.250.4"
      label="Slab of 250ml Cans (6 x 4-packs)"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeSlab.330.4"
      label="Slab of 330ml Cans (6 x 4-packs)"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeSlab.330.6"
      label="Slab of 330ml Cans (4 x 6-packs)"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeSlab.330.10"
      label="Slab of 330ml Cans (3 x 10-packs)"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeSlab.440.4"
      label="Slab of 440ml Cans (6 x 4-packs)"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
  </div>
);

const CaseBarcodes = () => (
  <div className="column">
    <h2>Individual Bottles</h2>
    <Form.Item
      name="barcodeCase.330"
      label="Case of 330ml Bottles"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeCase.375"
      label="Case of 375ml Bottles"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeCase.650"
      label="Case of 650ml Bottles"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeCase.750"
      label="Case of 750ml Bottles"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>

    <h2>Packs</h2>
    <Form.Item
      name="barcodeCase.330.6"
      label="Case of 330ml Bottles (4 x 6-packs)"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeCase.375.6"
      label="Case of 375ml Bottles (4 x 6-packs)"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="barcodeCase.750.12"
      label="Case of 750ml Bottles (Crushed 12-pack)"
      labelCol={{ span: 24 }}
    >
      <Input />
    </Form.Item>
  </div>
);
// ===== KEG LABELS NOT CURRENTLY REQUIRED =====
// const KegBarcodes = () => (
//   <div className="column">
//     <Form.Item
//       name="barcodeKeg.20"
//       label="Keg 20L"
//       labelCol={{ span: 24 }}
//     >
//       <Input />
//     </Form.Item>
//     <Form.Item
//       name="barcodeKeg.30"
//       label="Keg 30L"
//       labelCol={{ span: 24 }}
//     >
//       <Input />
//     </Form.Item>
//     <Form.Item
//       name="barcodeKeg.50"
//       label="Keg 50L"
//       labelCol={{ span: 24 }}
//     >
//       <Input />
//     </Form.Item>
//   </div>
// );
// =============================================

Barcodes.propTypes = {
  type: PropTypes.oneOf(['base', 'slab', 'case']).isRequired,
};

export default Barcodes;
